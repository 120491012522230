<template>
  <!-- 我的项目---创建项目组件 -->
  <div id="CreateProject" v-loading="loading">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <!-- 头部 -->

      <!-- <Header></Header> -->

      <div class="content">
        <!-- 头部选择 box -->
        <div class="title">
          <!-- 标题 -->
          <div class="headline">
            <div class="Theme">{{ $t(`message.Project.two`) }}</div>
            <div class="projectName">
              {{ Result.project_name }}
            </div>
            <div class="img" @click="isShowChangeName" v-show="Result.length != 0">
              <img src="https://newstore.vynior.com/edit-pen%402x.png" alt="" />
            </div>
          </div>
          <!-- 重新上传 方法 -->
          <el-upload
            :http-request="uploadFiles"
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreviews"
            :on-remove="handleRemoves"
            :before-remove="beforeRemoves"
            multiple
            :on-exceed="handleExceeds"
            :file-list="fileLists"
            :show-file-list="false">
            <el-button style="display: none" size="small" type="primary" id="ups">点击上传</el-button>
          </el-upload>
          <!-- 默认状态草稿箱 -->
          <div class="autoDraft" v-show="draftStatus == 0">
            <!-- <div class="status" @click="goDraft"> -->
            <div class="status">
              <img src="https://newstore.vynior.com/status1.png" alt="" />
            </div>
            <div class="title">{{ $t(`message.Project.new.auto`) }}</div>
          </div>
          <!-- 存储状态草稿箱 -->
          <div class="autoDraft" v-show="draftStatus == 1" :class="draftStatus == 1 ? 'autoDraft_two' : ''">
            <div class="status">
              <img src="https://newstore.vynior.com/refreshs%402x.png" alt="" />
            </div>
            <div class="title">{{ $t(`message.Project.new.Saving`) }}</div>
          </div>
          <!-- 存储成功草稿箱 -->
          <div class="autoDraft" v-show="draftStatus == 2" :class="draftStatus == 2 ? 'autoDraft_three' : ''">
            <div class="status">
              <img src="https://newstore.vynior.com/bingo_bbb.png" alt="" />
            </div>
            <div class="title">{{ $t(`message.Project.new.Saved`) }}</div>
          </div>
          <div class="Cancel" @click="back_project">
            <span>{{ $t(`message.Project.new.Cancel`) }}</span>
          </div>

          <div class="complete" @click="_complete">
            <span> {{ $t(`message.Project.new.Create`) }}</span>
          </div>
        </div>
        <!-- 产品操作区域 -->
        <div class="editProduct">
          <div class="addProduct" @click="addProduct" v-show="$store.state.lang == 'cn'">
            <img src="https://newstore.vynior.com/xinzengcc.png" alt="" />
          </div>
          <div class="addProduct" @click="addProduct" v-show="$store.state.lang == 'en'">
            <img
              src="https://newstore.vynior.com/addproducts.png
"
              alt="" />
          </div>
          <div class="importData">
            <el-tooltip
              :open-delay="500"
              class="item"
              effect="dark"
              :content="$t(`message.Project.new.ImportTip`)"
              placement="bottom"
              popper-class="back_grounds">
              <button @click.stop="import_sure">{{ $t(`message.Project.new.Import`) }}</button>
            </el-tooltip>
          </div>
          <div class="edit" v-show="isDisable == true">
            <button @click="amendMethod">{{ $t(`message.Project.new.Edit`) }}</button>
          </div>
          <div class="editChange" v-show="isDisable == false">
            <span class="editz">{{ $t(`message.Project.new.Editing`) }}</span>
          </div>
          <!-- 取消 -->
          <!-- <div class="edit" v-show="isDisable == false">
          <button @click="CancleEdit">取消</button>
        </div> -->
          <div class="save">
            <button @click="JudgeNum" v-show="0">保存</button>
          </div>
          <div class="productCategory">
            <div class="category">{{ $t(`message.Project.new.Category`) }}</div>
            <div class="categoryValue" v-show="Result.length != 0">{{ $t(`message.Project.new.Suncare`) }}</div>
            <div class="categoryValues" v-show="Result.length == 0">{{ $t(`message.Project.new.Unselected`) }}</div>
          </div>
        </div>

        <!-- 项目内容 -->
        <div class="product-detail">
          <!-- 产品删除与复制 -->
          <div class="copyANDdelete">
            <div class="Copy" v-show="isDisable == false" @click="CopyMethod">{{ $t(`message.Project.new.Copy`) }}</div>
            <el-tooltip
              :open-delay="500"
              class="item"
              effect="dark"
              :content="$t(`message.Project.NotCopy`)"
              placement="bottom"
              popper-class="back_grounds">
              <div class="Copys" v-show="isDisable == true">{{ $t(`message.Project.new.Copy`) }}</div>
            </el-tooltip>
            <div class="Delete" @click="DeleteProduct" :class="isDisable == true ? 'addDelete' : ''">{{ $t(`message.Project.new.Delete`) }}</div>

            <div class="SaveProducts" @click="JudgeNum" v-show="isDisable != true">{{ $t(`message.Project.new.Save`) }}</div>
            <div class="CancelEDIT" @click="CancleEdit" v-show="isDisable != true">{{ $t(`message.Project.new.CancelEdit`) }}</div>
            <div class="SenseValue" :class="isDisable == true ? 'SenseValues' : ''">
              <div class="title">{{ $t(`message.Project.new.SensoryData`) }}</div>
              <div class="img">
                <img src="https://newstore.vynior.com/bingo.png" alt="" v-show="Result.project_name" />
                <img src="https://newstore.vynior.com/duoxuankkk.png" alt="" v-show="!Result.project_name" />
              </div>
              <div class="condition" v-show="Result.project_name">{{ $t(`message.Project.new.Loaded`) }}</div>
              <div class="conditions" v-show="!Result.project_name">{{ $t(`message.Project.new.Null`) }}</div>
            </div>
            <div class="instrument">
              <div class="title">{{ $t(`message.Project.new.InstrumentalData`) }}</div>
              <div class="condition">{{ $t(`message.Project.new.ComingSoon`) }}</div>
            </div>
          </div>
          <div class="content_box" ref="scrollRef" :class="Result.length == 0 ? 'clearAuto' : ''">
            <!-- 项目标题 -->
            <div class="product-header" v-show="$store.state.lang == 'cn'">
              <div class="allCheck">
                <el-checkbox v-model="allChecked"></el-checkbox>
              </div>
              <div
                class="pd_Name"
                v-for="(item, index) in ProductName"
                :key="index"
                :class="[
                  index == 0 ? 'CancelFlex' : '',
                  index == 0 ? 'Title' : '',
                  index > 11 ? 'changeColor' : '',
                  Result.length == 0 ? 'clearShadow' : '',
                ]">
                <span class="MustReat" v-show="index == 0">*</span>
                <div class="itemName">{{ item }}</div>
              </div>
            </div>
            <div class="product-header" v-show="$store.state.lang == 'en'">
              <div class="allCheck">
                <el-checkbox v-model="allChecked"></el-checkbox>
              </div>
              <div
                class="pd_Name"
                v-for="(item, index) in enProductName"
                :key="index"
                :class="[
                  index == 0 ? 'CancelFlex' : '',
                  index == 0 ? 'Title' : '',
                  index > 11 ? 'changeColor' : '',
                  Result.length == 0 ? 'clearShadow' : '',
                ]">
                <span class="MustReat" v-show="index == 0">*</span>
                <div class="itemName">{{ item }}</div>
              </div>
            </div>
            <!-- 项目信息 -->
            <div class="product-box">
              <div
                class="product-message"
                v-for="(item, index) in import_val"
                :key="index"
                :class="[index == import_val.length - 1 ? 'endBorder' : '']">
                <el-checkbox v-model="CheckedArr[index]"></el-checkbox>
                <div class="ProductData" v-for="(ite, inde) in item" :key="inde" :class="[inde == 0 ? 'TitelId' : '']">
                  <div
                    class="item"
                    :class="[
                      inde > 11 && !judge.test(ite) ? 'Warning' : '',
                      isDisable ? 'CancelBorder' : '',
                      index == repetitionIndex[1] && inde == 0 ? 'Warning' : '',
                    ]">
                    <input
                      v-if="inde != 4 && inde != 5 && inde != 8"
                      type="text"
                      v-model="item[inde]"
                      :disabled="isDisable"
                      @blur="setTowNum(index, inde)" />

                    <el-form ref="form">
                      <el-form-item v-if="inde == 4" :class="isDisable == true ? 'noneIcon' : ''">
                        <el-select v-model="item[4]" :placeholder="$t(`message.Project.Select3`)" :disabled="isDisable">
                          <el-option :label="$t(`message.Project.None`)" :value="$t(`message.Project.None`)"></el-option>
                          <el-option :label="$t(`message.Project.Prototype`)" :value="$t(`message.Project.Prototype`)"></el-option>
                          <el-option :label="$t(`message.Project.Market`)" :value="$t(`message.Project.Market`)"></el-option>
                          <el-option :label="$t(`message.Project.Benchmark`)" :value="$t(`message.Project.Benchmark`)"></el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item v-if="inde == 5" :class="isDisable == true ? 'noneIcon' : ''">
                        <el-select v-model="item[5]" :placeholder="$t(`message.Project.Select`)" :disabled="isDisable">
                          <el-option :label="$t(`message.Project.None`)" :value="$t(`message.Project.None`)"></el-option>
                          <el-option :label="$t(`message.Project.Cream`)" :value="$t(`message.Project.Cream`)"></el-option>
                          <el-option :label="$t(`message.Project.Lotion`)" :value="$t(`message.Project.Lotion`)"></el-option>
                          <el-option :label="$t(`message.Project.Gel`)" :value="$t(`message.Project.Gel`)"></el-option>
                          <el-option :label="$t(`message.Project.Oil`)" :value="$t(`message.Project.Oil`)"></el-option>
                          <el-option :label="$t(`message.Project.Other`)" :value="$t(`message.Project.Other`)"></el-option>
                          <el-option :label="$t(`message.Project.Smearmask`)" :value="$t(`message.Project.Smearmask`)"></el-option>
                          <el-option :label="$t(`message.Project.Sheetmask`)" :value="$t(`message.Project.Sheetmask`)"></el-option>
                          <el-option :label="$t(`message.Project.Toner`)" :value="$t(`message.Project.Toner`)"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item v-if="inde == 8" :class="isDisable == true ? 'noneIcon' : ''">
                        <el-select v-model="item[8]" :placeholder="$t(`message.Project.Select3`)" :disabled="isDisable">
                          <el-option :label="$t(`message.Project.None`)" :value="$t(`message.Project.None`)"></el-option>
                          <el-option :label="$t(`message.Project.Chemical`)" :value="$t(`message.Project.Chemical`)"></el-option>
                          <el-option :label="$t(`message.Project.Physical`)" :value="$t(`message.Project.Physical`)"></el-option>
                          <el-option :label="$t(`message.Project.Hybird`)" :value="$t(`message.Project.Hybird`)"></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- <el-form-item v-if="inde == 9" :class="isDisable == true ? 'noneIcon' : ''">
                        <el-select v-model="item[9]" placeholder="请选产品颜色" :disabled="isDisable">
                          <el-option label="白色" value="白色"></el-option>
                          <el-option label="黑色" value="黑色"></el-option>
                        </el-select>
                      </el-form-item> -->
                    </el-form>
                  </div>
                </div>
              </div>
            </div>

            <!-- 当前页面的空状态 -->
            <div class="null_page" v-show="import_val.length == 0">
              <div class="null_img">
                <img src="https://newstore.vynior.com/Group%2033553%402x.png" alt="" />
              </div>
              <div class="null_message">暂无新建产信息哦</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 空的项目信息,点击新建产品弹窗 -->
      <div class="notarize_deletep" v-show="Once_sureCreate">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureCreate == 1 ? 'changeColor' : ''">
              是否填写完成项目信息?
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sureCreate = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              填写后,方可进行新建产品操作
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sureCreate = 0">取消</span>
              <span class="sure" @click="Once_sureCreate = 0">确定</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 空的项目信息,点击存草稿弹窗 -->

      <div class="notarize_deletep" v-show="Once_Drafts">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureCreate == 1 ? 'changeColor' : ''">
              是否填写完成项目信息?
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_Drafts = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              填写后,方可进行存草稿操作
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_Drafts = 0">取消</span>
              <span class="sure" @click="Once_Drafts = 0">确定</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 未保存,提醒ui -->

      <div class="notarize_delete" v-show="Once_sureSave">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureSave == 1 ? 'changeMargan' : ''">
              {{ $t(`message.Message.Missing`) }}
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sureSave = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              请先进行保存或取消,方可进行其他操作
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sureSave = 0">取消</span>
              <span class="sure" @click="Once_sureSave = 0">确定</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 提醒填写产品信息ui -->
      <div class="notarize_deletes" v-show="Once_sure">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              {{ $t(`message.Message.Missing`) }}
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sure = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              {{ $t(`message.Message.FIELDS`) }}
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sure = 0"> {{ $t(`message.Message.Cancel`) }}</span>
              <span class="sure" @click="Once_sure = 0"> {{ $t(`message.Message.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 删除产品提示ui -->
      <div class="notarize_delete_p" v-show="Once_sureDelete">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureDelete == 1 ? 'changeMargin' : ''">
              {{ $t(`message.Project.new.DeleteThis`) }}
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_sureDelete = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              {{ $t(`message.Project.ActionUndone`) }}
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_sureDelete = 0"> {{ $t(`message.Project.Cancel`) }}</span>
              <span class="sure" @click="Delete_sure_button"> {{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 取消产品提示ui -->
      <div class="cancel_" v-show="Once_cancel">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" :class="Once_sureSave == 1 ? 'changeMargan' : ''">
              确定取消编辑产品信息吗？
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_cancel = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              修改的信息将不会保存
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_cancel = 0">取消</span>
              <span class="sure">确定</span>
            </div>
          </div>
        </div>
      </div>

      <!--重新导入数据提示ui -->
      <div class="notarize_import" v-show="Once_import">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              您确定重新上传项目文件？
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Once_import = 0" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              重新上传后，该项目当前数据将被上传覆盖
            </div>
            <div class="line_3">
              <span class="cancle" @click="Once_import = 0">取消</span>
              <span class="sure" @click="sure_import">确定</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 新建项目的导入提示框 -->
      <!--导入数据提示ui -->
      <div class="import_tip" v-show="$store.state.upload">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1">
              <div class="title">
                {{ $t(`message.Project.new.NewProject`) }}
              </div>
              <img src="https://newstore.vynior.com/cancelss.png" alt="" @click="back_project" />
            </div>
            <div class="line_2">
              <!-- 上传文件  中文上传 -->
              <div class="import_box cn" v-show="$store.state.lang == 'cn'">
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreviews"
                  :on-remove="handleRemoves"
                  :before-remove="beforeRemoves"
                  :on-exceed="handleExceeds"
                  :file-list="fileLists"
                  multiple
                  :http-request="uploadFiles"
                  :show-file-list="false"
                  :before-upload="beforeUpload">
                  <img src="https://newstore.vynior.com/uploads.png" alt="" class="logo" />
                  <div class="el-upload__text">{{ $t(`message.Project.new.Choosefile`) }}</div>
                  <img
                    src="https://newstore.vynior.com/scmm.png"
                    alt=""
                    class="button"
                    @click.stop="import_sure"
                    v-show="$store.state.lang == 'cn'" />
                </el-upload>

                <!-- <el-upload :http-request="uploadFiles" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
    :on-preview="handlePreviews" :on-remove="handleRemoves" :before-remove="beforeRemoves" multiple
    :on-exceed="handleExceeds" :file-list="fileLists" :show-file-list="false"> -->
              </div>
              <!-- 上传文件  英文上传 -->
              <div class="import_box en" v-show="$store.state.lang == 'en'">
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreviews"
                  :on-remove="handleRemoves"
                  :before-remove="beforeRemoves"
                  :on-exceed="handleExceeds"
                  :file-list="fileLists"
                  multiple
                  :http-request="uploadFiles"
                  :show-file-list="false"
                  :before-upload="beforeUpload">
                  <img src="https://newstore.vynior.com/uploads.png" alt="" class="logo" />
                  <div class="el-upload__text">{{ $t(`message.Project.new.Choosefile`) }}</div>

                  <img
                    src="https://newstore.vynior.com/shangchuans.png"
                    alt=""
                    class="button"
                    @click.stop="import_sure"
                    v-show="$store.state.lang == 'en'" />
                </el-upload>

                <!-- <el-upload :http-request="uploadFiles" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
    :on-preview="handlePreviews" :on-remove="handleRemoves" :before-remove="beforeRemoves" multiple
    :on-exceed="handleExceeds" :file-list="fileLists" :show-file-list="false"> -->
              </div>
              <!-- 下载模板 -->
              <div class="download_box">
                <img src="https://newstore.vynior.com/dlmm.png" class="logo" />
                <span>{{ $t(`message.Project.new.downLoad`) }}</span>
                <img src="https://newstore.vynior.com/xzmm.png" class="down_load" @click="downFile" v-show="$store.state.lang == 'cn'" />
                <img src="https://newstore.vynior.com/xiazaidown.png" class="down_load" @click="downFileEN" v-show="$store.state.lang == 'en'" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--修改项目编号 -->
      <div class="ChangeProjectNumber" v-show="isAmendNumber">
        <div class="content">
          <div class="line1">
            <div class="title">{{ $t(`message.Project.new.ProjectNo`) }}</div>
            <div class="img" @click="isAmendNumber = false">
              <img src="https://newstore.vynior.com/cancelss.png" alt="" />
            </div>
          </div>
          <div class="line2">
            <input type="text" v-model="amendProjectValue" />
          </div>
          <div class="line3">
            <button class="cancel" @click="isAmendNumber = false">{{ $t(`message.Project.Cancel`) }}</button>
            <button class="confirm" @click="amendProject">{{ $t(`message.Project.new.Save`) }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HttpClient } from '@/utils/http';
import { message } from '@/utils/resetMessage';
// import Header from '../../components/Header.vue';
import axios from 'axios';
// import { reverse } from 'dns';
export default {
  data() {
    return {
      FromDraft: false, //从草稿箱来的.
      loading: false, //过渡动画
      draftStatus: 0, //草稿箱的状态显示
      resourceProject_name: '', //存储每次修改前最初的数据
      resourceCategory: '', //存储每次修改前最初的数据
      resourceProduct: [], //存储每次修改前最初的数据.
      repetitionIndex: [], //重复的产品名字下标
      amendProjectValue: '', //修改的项目名称
      isAmendNumber: false, //修改窗口显示
      ProductNull: [], //新建产品的数组model
      CheckedArr: [], //产品多选框数组
      allChecked: false, //全选按钮
      isDisable: false,
      judge: new RegExp(/^([0-9]|1[0-5])(\.[0-9])?$/), //input框输入0-15正则表达式
      Result: [], //导入的全部数据
      ProductName: [
        '产品名称',
        '品牌',
        '生产日期',
        '生产批号',
        '产品来源',
        '产品性状',
        'SPF值',
        'PA指数',
        '物理化学防晒',
        '产品颜色',
        '上市国家',
        '备注',
        '产品质地细腻度',
        '产品光泽度',
        '产品透明度',
        '产品质地厚薄',
        '产品弹性',
        '产品软硬程度',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品拉丝性',
        '小垫子',
        '增白程度',
        '产品遮盖能力',
        '涂抹难易程度(2圈)',
        '增白程度(5圈)',
        '涂抹难易程度',
        '泛白程度',
        '产品水感',
        '产品油感',
        '皮肤清凉感',
        '增白程度(即时)',
        '皮肤光亮程度(即时)',
        '皮肤光滑程度(即时)',
        '皮肤粘手程度(即时)',
        '产品残留物的量(即时)',
        '皮肤水感(即时)',
        '皮肤油感(即时)',
        '增白程度(2分钟)',
        '皮肤光亮程度(2分钟)',
        '皮肤光滑程度(2分钟)',
        '皮肤粘手程度(2分钟)',
        '产品残留物的量(2分钟)',
        '皮肤水感(2分钟)',
        '皮肤油感(2分钟)',
        '增白程度(5分钟)',
        '皮肤光亮程度(5分钟)',
        '皮肤光滑程度(5分钟)',
        '皮肤粘手程度(5分钟)',
        '产品残留物的量(5分钟)',
        '皮肤水感(5分钟)',
        '皮肤油感(5分钟)',
        '总体吸收速度',
      ], //产品维度
      enProductName: [
        'Product Name',
        'Brand',
        'Date of Production',
        'Batch No.',
        'Source',
        'Phase',
        'SPF value',
        'PA rating',
        'Formulation System',
        'Color',
        'Country for listing',
        'Note',
        ' Fineness of texture',
        'Reflection of product',
        'Transparence of product',
        'Thickness/ Flow',
        'Bounce',
        'Firmness',
        'Peaking',
        'Pick up',
        'Stringy',
        'Cushion',
        'Instant whitening',
        'Covering',
        'Spreadability(2rubs)',
        'Whitening(5rubs)',
        'Spreadability(10rubs)',
        'Chalky',
        'Product watery',
        'Product greasiness',
        'Cooling',
        'Whitening(instant)',
        'Shininess(instant)',
        'Smoothness(instant)',
        'Stickiness(instant)',
        'Residue(instant)',
        'Watery(instant)',
        'Oiliness(instant)',
        'Whitening(2min)',
        'Shininess(2min)',
        'Smoothness(2min)',
        'Stickiness(2min)',
        'Residue(2min)',
        'Watery(2min)',
        'Oiliness(2min)',
        'Whitening(5min)',
        'Shininess(5min)',
        'Smoothness(5min)',
        'Stickiness(5min)',
        'Residue(5min)',
        'Watery(5min)',
        'Oiliness(5min)',
        'Speed of absorption',
      ],
      xlsx_name: '', //上传的文件名字
      // upload_success: true,
      new_program: [],
      ys_import_data: [], //存储数据
      down_name: '',
      not_wj: 0,
      copy_data: [], //复制的产品信息
      cancel_data: [],
      new_data: [], //新建的第一个数据
      cancel_i: -1, //取消下表记录
      Once_cancel: 0, //确认取消编辑
      fore_ms: 0,
      draft_id: '', //从草稿箱进入 存入的的id
      not_import: 1,
      pro_name: '', //项目名称
      timers: null, //定时器
      change_dataval: 0, //是否覆盖
      import_val: [], //用户导入的数据
      fileList: [],
      fileLists: [],
      delete_i: -1, //删除下标的传递
      one_m: 0,
      one_ms: 0,
      two_m: 0,
      three_m: 0,
      fore_m: 0,
      timer: 0, //计时器的起始值
      auto_drafts: 0, //保存之草稿箱的时间信息
      Once_import: 0, //导入数据提示ui
      // Delete_sure_button: 0,//确认删除
      Once_sureDelete: 0, //删除产品的ui
      Once_Drafts: '', //显示隐藏 存草稿 项目为空的提示ui
      category_arrs: '',
      cz_arr: {
        cz1: '修改',
        cz3: '删除',
        cz2: '复制',
        quality_Arr: [{ quality_val: '固态' }, { quality_val: '液态' }, { quality_val: '气雾' }, { quality_val: '粉末' }],
        color_Arr: [
          { colorval: '白色' }, //产品颜色
          { colorval: '黄色' }, //产品颜色
          { colorval: '透明' }, //产品颜色
          { colorval: '粉色' }, //产品颜色
        ],
        classification_Arr: [
          { classification_val: '开发原型', order: '' },
          { classification_val: '市售产品', order: '' },
          { classification_val: '竞争品牌', order: '' },
        ], //产品品类
        disType: true, //默认不可修改
      },
      products_nameList: [], //存储新建的产品信息

      num: 0,
      Res_ID: [], //接受请求回来的项目id
      Res_Pd: [], //接收上传产品返回来的产品结果
      pro_classification: '', //项目产品的产品来源
      formInline: '',
      null_products: 1, //控制空状态显示隐藏
      select_one: '', //option val
      Once_sure: 0, //判断未填写的产品信息提示ui
      Once_sureCreate: 0, //判断提示框是否弹出
      Once_sureSave: 0, //判断保存提示框
      project_id: '', // 输入框内容
      NowTime: '', //进入项目的时间
      add_border: -1, // 被更改的数据下标
      clear_border: -1, //清除边框
      // show_choose_num: 0, // 控制显示隐藏
      warn_fist: 0,
      warn_fist2: 0,
      numNoName: '', //弹框
      product_index: -1, //记录下标
      product_cfn_warn: 0, //控制产品表格警告
      product_name_warn: 0, //控制产品name表格警告
      Products_model: {
        cz1: '保存',
        cz2: '恢复',
        cz3: '',
        disType: false, //默认可以修改

        name: '',
        classification: '',
        classification_Arr: [
          { classification_val: '开发原型', order: '' },
          { classification_val: '市售产品', order: '' },
          { classification_val: '竞争品牌', order: '' },
        ], //产品品类
        category: '', //产品分类
        product_number: '', //产品编号
        color: '',
        color_Arr: [
          { colorval: '白色' }, //产品颜色
          { colorval: '黄色' }, //产品颜色
          { colorval: '透明' }, //产品颜色
          { colorval: '粉色' }, //产品颜色
        ],

        product_place: '', //产地
        brand: '', //品牌
        quality: '',
        quality_Arr: [{ quality_val: '固态' }, { quality_val: '液态' }, { quality_val: '气雾' }, { quality_val: '粉末' }], //质地

        remark: '', //备注
        _id: '',
      },

      import_model: {
        cz1: '修改',
        cz2: '复制',
        cz3: '删除',
        disType: false, //默认可以修改
        // product_id: "X3tucj8LYuBo638gq6pQOpC1", //产品_Id
        // project_id: "lRN7rsBiB2DMMONmDV7CClOQ", //项目_Id
        // project_name: "FS-066", //项目编号
        name: '',
        classification: '',
        classification_Arr: [
          { classification_val: '开发原型', order: '' },
          { classification_val: '市售产品', order: '' },
          { classification_val: '竞争品牌', order: '' },
        ], //产品品类
        category: '', //产品分类
        product_number: '', //产品编号
        color: '',
        color_Arr: [
          { colorval: '白色' }, //产品颜色
          { colorval: '黄色' }, //产品颜色
          { colorval: '透明' }, //产品颜色
          { colorval: '粉色' }, //产品颜色
        ],
        product_place: '', //产地
        brand: '', //品牌
        quality: '',
        quality_Arr: [{ quality_val: '固态' }, { quality_val: '液态' }, { quality_val: '气雾' }, { quality_val: '粉末' }], //质地

        remark: '', //备注
        _id: '',
      },
      Products_val: [],

      // 品类数组
      category_arr: [],
      cvalue: 0,
      val: '修改',
      vals: '保存',
      cancel: '恢复',
      copy: '复制',
      delete: '删除',
      cancels: '取消',
      disType: true, //默认禁止修改

      kong: 0,
      teshu: 0,
      notOnly: 0,
      max: 0,
    };
  },
  watch: {
    // 监视select的值,给add用
    select_one: {
      handler(newVal, oldVar) {
        if (newVal != oldVar) {
          this.Products_model.category = newVal;

          for (let index = 0; index < this.Products_val.length; index++) {
            this.Products_val[index].category = newVal;
          }
        }
      },
    },
    Products_val(newVal) {
      if (newVal != 0) {
        this.null_products = 0;
      } else {
        this.null_products = 1;
      }
    },
    // 监听计时器的变化
    timer: {
      handler(newv, oldv) {
        if (newv != oldv) {
          if (newv % 180 == 0) {
            this.draftStatus = 1;
            this.goDraft();
          }
        }
      },
    },

    //全选产品数据
    allChecked(newvalue) {
      if (newvalue == true) {
        for (let i = 0; i < this.CheckedArr.length; i++) {
          this.CheckedArr[i] = true;
        }
      } else {
        for (let i = 0; i < this.CheckedArr.length; i++) {
          this.CheckedArr[i] = false;
        }
      }
    },

    //监听是否有数据,没有数据就不调用计时器
    Result(newval) {
      if (newval.length != 0) {
        this.set_time();
      }
    },
    '$store.state.lang': {
      handler: function () {
        if (this.$store.state.lang == 'en') {
          this.import_val.forEach((item) => {
            if (item[4] == '市售产品') {
              item[4] = 'In-market Product';
            } else if (item[4] == '开发原型') {
              item[4] = 'Prototype';
            } else if (item[4] == '竞争品牌') {
              item[4] = 'Benchmark';
            } else if (item[4] == '无') {
              item[4] = 'None';
            }
            switch (item[5]) {
              case '膏霜':
                item[5] = 'Cream';
                break;
              case '乳液':
                item[5] = 'Lotion';
                break;
              case '啫喱':
                item[5] = 'Gel';
                break;
              case '油类':
                item[5] = 'Oil';
                break;
              case '其他':
                item[5] = 'Other';
                break;
              case '涂抹式面膜':
                item[5] = 'Smear mask';
                break;
              case '贴片式面膜':
                item[5] = 'Sheet mask';
                break;
              case '水类':
                item[5] = 'Toner';
                break;
              case '无':
                item[5] = 'None';
                break;
            }
            switch (item[8]) {
              case '化学':
                item[8] = 'Chemical';
                break;
              case '物理':
                item[8] = 'Physical';
                break;
              case '物化结合':
                item[8] = 'Hybird';
                break;
              case '无':
                item[8] = 'None';
                break;
              default:
                break;
            }
          });
        } else {
          this.import_val.forEach((item) => {
            if (item[4] == 'In-market Product') {
              item[4] = '市售产品';
            } else if (item[4] == 'Prototype') {
              item[4] = '开发原型';
            } else if (item[4] == 'Benchmark') {
              item[4] = '竞争品牌';
            } else if (item[4] == 'Benchmark') {
              item[4] = '竞争品牌';
            } else if (item[4] == 'None') {
              item[4] = '无';
            }
            switch (item[5]) {
              case 'Cream':
                item[5] = '膏霜';
                break;
              case 'Lotion':
                item[5] = '乳液';
                break;
              case 'Gel':
                item[5] = '啫喱';
                break;
              case 'Oil':
                item[5] = '油类';
                break;
              case 'Other':
                item[5] = '其他';
                break;
              case 'Smear mask':
                item[5] = '涂抹式面膜';
                break;
              case 'Sheet mask':
                item[5] = '贴片式面膜';
                break;
              case 'Toner':
                item[5] = '水类';
                break;
              case 'None':
                item[5] = '无';
                break;
            }
            switch (item[8]) {
              case 'Chemical':
                item[8] = '化学';
                break;
              case 'Physical':
                item[8] = '物理';
                break;
              case 'Hybird':
                item[8] = '物化结合';
                break;
              case 'None':
                item[8] = '无';
                break;
              default:
                break;
            }
          });
        }
      },
    },
    numNoName: {
      handler() {
        if (this.numNoName == 1) {
          console.log('没有项目编号');
          message({
            type: 'error',
            message: this.$t(`message.Message.emptyNo`),
            showClose: true,
            customClass: 'customClass',
          });
        } else {
          console.log('非模板文档');
          message({
            type: 'error',
            message: this.$t(`message.Message.emptyNo1`),
            showClose: true,
            customClass: 'customClass',
          });
        }
      },
    },
  },
  methods: {
    //存储草稿操作
    async goDraft() {
      if (this.Result._id == undefined) {
        if (this.Result.category == 'Suncreen') {
          this.Result.category = '防晒霜';
        }
        let res = await HttpClient.post('program/program_save1', {
          updates: {
            project_name: this.Result.project_name,
            category: this.Result.category,
            status: '草稿箱',
            sub: this.$store.state.userid,
            panel_name: this.xlsx_name,
            temporary_data: this.import_val,
          },
          // productDataList: this.import_val,
        });
        console.log('存储ID返回结果 :>> ', res);
        //改变 id状态;
        this.Result._id = res.id;
        setTimeout(() => {
          this.draftStatus = 2;
          setTimeout(() => {
            this.draftStatus = 0;
          }, 3000);
        }, 3000);
      } else {
        if (this.Result.category == 'Suncreen') {
          this.Result.category = '防晒霜';
        }
        let res = await HttpClient.post('program/program_save1', {
          updates: {
            project_name: this.Result.project_name,
            category: this.Result.category,
            status: '草稿箱',
            sub: this.$store.state.userid,
            panel_name: this.xlsx_name,
            temporary_data: this.import_val,
            _id: this.Result._id,
          },
          // productDataList: this.import_val,
        });
        console.log('存储ID返回结果 :>> ', res);
        setTimeout(() => {
          this.draftStatus = 2;
          setTimeout(() => {
            this.draftStatus = 0;
          }, 3000);
        }, 3000);
      }
      this.draftNum();
    },
    //同步导航栏草稿箱数量

    async draftNum() {
      //用来同步草稿箱显示的总数量
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        status: '草稿箱',
        page: 0,
        size: 999,
      }).then((res) => {
        let choiceNum = res.result.length;

        this.$EventBus.$emit('sendHavaDrafts_Num', choiceNum);
      });
    },
    //返回到我的项目首页
    async back_project() {
      this.$EventBus.$emit('back_project', 'myproject');
      if (this.FromDraft == true) {
        console.log('我是从草稿箱来的');
      } else {
        console.log('我是新建的!');
        if (this.Result._id != undefined) {
          // 请求删除数据
          await HttpClient.post('program/delete', {
            id: this.Result._id,
          }).then((res) => {
            // 删除返回信息
            console.log(res);
            // this.allin = false;
          });
          this.draftNum();
        }
      }
    },

    //! 下载模板1中文
    downFile() {
      console.log(111);
      axios
        .post(
          'product_template/receptionDownload', // 请求地址
          {
            product_name: this.category_arrs.toString(), // 下载文件名称
            language: 'cn',
          },
          {
            responseType: 'arraybuffer', // 响应结果格式
          }
        )
        .then((response) => {
          var blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats;charset=utf-8',
          }); // 创建一个blob对象将返回数据处理为blob格式
          var url = window.URL.createObjectURL(blob); // 创建一个url对象，为数据创建一个临时url地址
          console.log(blob, 'blob');
          var a = document.createElement('a'); //创建一个a标签
          a.href = url; // 将url地址赋值给a标签href属性
          a.download = 'FAST数据导入模板.xlsx';
          a.click(); // 触发a标签点击
          window.URL.revokeObjectURL(url); // 将创建的url对象清除
        });
    },
    //下载模板english
    downFileEN() {
      axios
        .post(
          'product_template/receptionDownload', // 请求地址
          {
            product_name: this.category_arrs.toString(), // 下载文件名称
            language: 'en',
          },
          {
            responseType: 'arraybuffer', // 响应结果格式
          }
        )
        .then((response) => {
          var blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats;charset=utf-8',
          }); // 创建一个blob对象将返回数据处理为blob格式
          var url = window.URL.createObjectURL(blob); // 创建一个url对象，为数据创建一个临时url地址
          console.log(url, 'url');
          var a = document.createElement('a'); //创建一个a标签
          a.href = url; // 将url地址赋值给a标签href属性
          a.download = 'FAST Data Import Template.xlsx';
          a.click(); // 触发a标签点击
          window.URL.revokeObjectURL(url); // 将创建的url对象清除
        });
    },
    //下载模板2
    downFile1() {
      let a = document.createElement('a');
      a.href = 'https://www.ht-fast.com/FAST属性数据导入正式模板V1.xlsx'; // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.click();
    },
    //下载模板3
    async downFile2() {
      let response = await axios.get(this.down_name, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      let a = document.createElement('a');
      let href = window.URL.createObjectURL(blob);

      a.href = href;
      a.download = this.xlsx_name;
      // a.href = this.down_name; // 这里的请求方式为get，如果需要认证，接口上需要带上token
      a.click();
      window.URL.revokeObjectURL(href);
    },
    // 删除操作
    Delete_item(value, i) {
      if (value == '删除') {
        console.log(i);
        this.delete_i = i;
        this.Once_sureDelete = 1;
      }
    },
    //删除确认操作
    Delete_sure_button() {
      let arr = [];
      this.CheckedArr.forEach((item, index) => {
        if (item == true) {
          arr.push(index);
        }
      });
      // arr=reverse(arr)
      for (let index = arr.length - 1; index >= 0; index--) {
        console.log(arr[index]);
        this.import_val.splice(arr[index], 1);
        this.CheckedArr.splice(arr[index], 1);
      }

      this.Once_sureDelete = 0;
    },
    //导入数据遮罩
    import_sure() {
      if (this.project_id == '' && this.select_one == '' && this.Products_val.length == 0) {
        this.Once_import = 0;

        // console.log('1111');

        let up = document.getElementById('ups');
        console.log(up);
        up.click();
      } else {
        this.Once_import = 1;

        console.log(this.project_id, this.select_one, this.Products_val.length);
      }
    },

    sure_import() {
      this.Once_import = 0;

      let up = document.getElementById('ups');
      console.log(up);
      up.click();
    },

    // 控制隐藏操作
    // show_choose() {
    //   this.show_choose_num = !this.show_choose_num;
    // },
    // 更改项目名称
    onBlur() {
      var judgeFn = new RegExp(/^[A-Za-z0-9-]{1,}$/g);
      if (this.project_id != '' && judgeFn.test(this.project_id)) {
        // message({
        //   message: "修改成功！",
        //   type: "success",
        // });
        this.warn_fist = 0;
        this.warn_fist2 = 0;
      } else {
        this.warn_fist2 = 1;
        this.warn_fist = 0;
        message.error('项目编号信息错误');
      }

      if (this.project_id.length > 20) {
        this.warn_fist = 1;
      }
    },
    // add 新增产品
    add_Product() {
      const that = this;

      that.clear_border = -1;
      //控制提示ui的显示隐藏
      if (that.project_id == '' || that.select_one == '') {
        that.Once_sureCreate = !that.Once_sureCreate;
      } else {
        that.judge_save();
        if (that.Once_sureSave != 1) {
          // 新增产品
          that.Products_val.unshift(JSON.parse(JSON.stringify(that.Products_model)));
          this.new_data = JSON.parse(JSON.stringify(that.Products_val[0]));
          console.log('new', this.new_data);
          console.log(that.Products_val);
          that.timers = setInterval(() => {
            that.clear_s();
          }, 10);
        }
      }

      //两种方式 替换- -
    },
    async clear_s() {
      let dis_s = document.getElementById('aaa');
      // console.log(dis_s);
      dis_s.text = '';
      let dis_ss = document.getElementById('dis_1');
      // console.log(dis_s);
      dis_ss.text = '';
      let dis_ = document.getElementById('dis_a').options;
      console.log(dis_);
      dis_[0].text = '';
      console.log(123);
      clearInterval(this.timers);
      this.timers = null;
    },
    // 判断时候有未保存
    judge_save() {
      for (let index = 0; index < this.Products_val.length; index++) {
        if (this.Products_val[index].cz1 == '保存') {
          this.Once_sureSave = 1;
        } else {
          this.Once_sureSave = 0;
        }
      }
    },
    //最后一步 完成 ->跳转到单品分析的操作
    async _complete() {
      console.log('this.Result._id :>> ', this.Result._id);
      //判断是否保存产品
      if (this.isDisable == false) {
        message({
          type: 'warning',
          message: this.$store.state.lang === 'cn' ? '请先保存产品，再保存项目!' : 'Please save the product first, then save the project!',
          showClose: true,
        });
      } else {
        //判断是否是草稿箱内的数据(新建的的数据)
        if (this.Result._id == undefined) {
          if (this.Result.category == 'Suncreen') {
            this.Result.category = '防晒霜';
          }
          let res = await HttpClient.post('program/program_save1', {
            updates: {
              project_name: this.Result.project_name,
              category: this.Result.category,
              status: '已保存',
              sub: this.$store.state.userid,
              panel_name: this.xlsx_name,
              temporary_data: [],
            },
            productDataList: this.import_val,
          });
          console.log('res :>> ', res);
          if (res.status == 1) {
            message({
              type: 'warning',
              message: this.$t(`message.Message.ProjectNo`),
              showClose: true,
            });
          } else {
            this.$EventBus.$emit('send_success', 'Haveproject');
            message({
              type: 'success',
              message: this.$t(`message.Message.Saved`),
              showClose: true,
            });
          }
        } else {
          //草稿箱的数据
          if (this.Result.category == 'Suncreen') {
            this.Result.category = '防晒霜';
          }
          let res = await HttpClient.post('program/program_save1', {
            updates: {
              project_name: this.Result.project_name,
              category: this.Result.category,
              status: '已保存',
              sub: this.$store.state.userid,
              panel_name: this.xlsx_name,
              _id: this.Result._id,
              temporary_data: [],
            },
            productDataList: this.import_val,
          });
          console.log('res :>> ', res);
          if (res.status == 1) {
            message({
              type: 'warning',
              message: this.$t(`message.Message.ProjectNo`),
              showClose: true,
            });
          } else {
            this.$EventBus.$emit('send_success', 'Haveproject');
            message({
              type: 'success',
              message: this.$t(`message.Message.Saved`),
              showClose: true,
            });
          }
          //用来同步草稿箱显示的总数量
          await HttpClient.post('program/searchlist', {
            user_id: this.$store.state.userid,
            project_name: '',
            startdate: '',
            enddate: '',
            productinfo: '',
            status: '草稿箱',
            page: 0,
            size: 999,
          }).then((res) => {
            let choiceNum = res.result.length;

            this.$EventBus.$emit('sendHavaDrafts_Num', choiceNum);
          });
        }
      }
    },
    // 存草稿
    async _drafts() {
      const that = this;

      console.log(that.select_one);
      if (that.project_id != '' && that.select_one != '') {
        var judgeFns = new RegExp(/^((?!\\|\/|:|\*|\?|<|>|\||'|%|\#|\$|\@|\?|\!).){1,}$/); //判断字符>1
        // var judgeFn = new RegExp(/^[A-Za-z0-9-]{1,}$/g); //判断字符>1
        var judgeFn = new RegExp(/^\S*$/);
        // 判断是否是空格
        if (!judgeFn.test(that.project_id)) {
          that.kong = 1;
        } else {
          that.kong = 0;
        }
        // 判断是否是特殊字符
        if (!judgeFns.test(that.project_id)) {
          that.teshu = 1;
        } else {
          that.teshu = 0;
        }
        // 判断长度
        if (that.project_id.length > 20) {
          that.max = 1;
        } else {
          that.max = 0;
        }

        if (that.max == 0 && that.kong == 0 && that.teshu == 0) {
          that.judge_save();
          if (that.Once_sureSave != 1) {
            if (this.draft_id != '') {
              console.log('第一个请求,草稿箱点击编辑,再次存草稿');
              // 发送项目编号请求
              await HttpClient.post('program/add', {
                type: '编辑项目',
                updates: {
                  _id: this.draft_id,
                  // _id: this.Res_ID.program,

                  project_name: that.project_id,
                  category: that.select_one,
                  status: '草稿箱',
                  sub: that.$store.state.userid,
                  // panel_files: "",
                  // equipment_files: "",
                },
              }).then(() => {
                // that.Res_ID = res;
                console.log(that.num);
                console.log(that.Res_ID);
              });
            } else {
              // 发送项目编号请求
              console.log('第二个请求');
              await HttpClient.post('program/add', {
                type: '新建项目',
                updates: {
                  project_name: that.project_id,
                  category: that.select_one,
                  _id: this.Res_ID.program,
                  status: '草稿箱',
                  sub: that.$store.state.userid,
                  // panel_files: "",
                  // equipment_files: "",
                },
              }).then((res) => {
                that.Res_ID = res;
                console.log(that.num);
                console.log(that.Res_ID);
              });
              // this.timer = 0;
            }

            // 将产品发送给后台
            // 发送数据
            for (let i = 0; i < that.Products_val.length; i++) {
              await HttpClient.post('ctmproduct/add', {
                updates: {
                  name: that.Products_val[i].name,
                  classification: that.Products_val[i].classification,
                  category: that.Products_val[i].category,
                  product_number: that.Products_val[i].product_number,
                  color: that.Products_val[i].color,
                  product_place: that.Products_val[i].product_place,
                  brand: that.Products_val[i].brand,
                  quality: that.Products_val[i].quality,
                  remark: that.Products_val[i].remark,
                  spf: that.Products_val[i].spf,
                  Program: that.Res_ID.program,
                },
              }).then((res) => {
                that.Res_Pd = res;
                console.log(that.Res_Pd);
              });
            }
          }

          // this.$EventBus.$emit("pageChange", "MyDrafts");
        }
        this.timer = 301;
      } else {
        this.Once_Drafts = 1;
        this.timer = 0;
        // this.auto_drafts = 0;
      }

      //用来请求 总数量
      await HttpClient.post('program/searchlist', {
        user_id: this.$store.state.userid,
        project_name: '',
        startdate: '',
        enddate: '',
        productinfo: '',
        status: '草稿箱',
        page: 0,
        size: 999,
      }).then((res) => {
        let choiceNum = res.result.length;
        // 同步下方的 选择个数和 总体项目数量
        // this.choiceNum = this.Res_Start_data.result.length;
        // console.log(this.Res_Start_data.result);
        this.$EventBus.$emit('sendHavaDrafts_Num', choiceNum);
      });
      // that.timer = 0;
    },

    // 本地时间
    getNowDate() {
      var date = new Date();
      // var sign2 = ':';
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒

      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = '0' + month;
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = '0' + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = '0' + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = '0' + seconds;
      }
      this.NowTime = year + '/' + month + '/' + day;
    },
    //全部保存
    allSave() {
      console.log(this.Products_val);
      for (let index = 0; index < this.Products_val.length; index++) {
        if (this.Products_val[index].cz1 == '保存') {
          this.changes(this.Products_val[index].cz1, index);
        }
      }
    },
    //回到顶部
    top_message() {
      if (this.$refs.scrollRef.scrollTop == 0) {
        message({
          showClose: true,
          message: '当前已经是顶部信息',
          type: 'warning',
        });
      } else {
        this.$refs.scrollRef.scrollTop = 0;
      }
    },
    down_load1() {
      // 这个是要下载的文件名称 这个会在查找接口返回给你直接获取就行
      const product_name = '精华';
      HttpClient.post(
        'product_template/instrumentInfo_download', // 请求地址
        {
          product_name, // 下载文件名称
        },
        {
          responseType: 'blob', // 响应结果格式
        }
      ).then((response) => {
        var blob = new Blob([response.data]); // 创建一个blob对象将返回数据处理为blob格式
        var url = window.URL.createObjectURL(blob); // 创建一个url对象，为数据创建一个临时url地址
        var a = document.createElement('a'); //创建一个a标签
        a.href = url; // 将url地址赋值给a标签href属性
        a.download = '模板.xlsx'; // 设置下载文件名称 最后设置为查找接口返回的不是obs名称 正常的 senseInfo_name
        a.click(); // 触发a标签点击
        window.URL.revokeObjectURL(url); // 将创建的url对象清除
      });
    },

    // 请求用户的产品品类
    async req_category() {
      let res = await HttpClient.post('subuser/getUesrProduct', {
        _id: this.$store.state.userid,
      });
      console.log(res);
      this.category_arrs = res.classification;
      console.log(this.category_arrs);
    },

    //计时器
    set_time() {
      var time = setInterval(() => {
        this.timer++;
      }, 1000);
      console.log(this.timer);

      this.$once('hook:beforeDestroy', () => {
        clearInterval(time);
      });
    },
    // 上传感官属性数据
    async import_data() {},

    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    async not_imports() {
      this.not_import = 1;
      console.log(this.Res_ID.program);
      if (this.Res_ID.program != undefined) {
        await HttpClient.post('program/delete_panel', {
          id: this.Res_ID.program,
        }).then((res) => {
          console.log(res);
          if (res.message == 'success') {
            this.not_wj = 0;
            this.Products_val = [];
            this.select_one = '';
            this.project_id = '';
            this.products_nameList = [];
          }
        });
      } else {
        await HttpClient.post('program/delete_panel', {
          id: this.new_program._id,
        }).then((res) => {
          console.log(res);
          if (res.message == 'success') {
            this.not_wj = 0;
            this.Products_val = [];
            this.select_one = '';
            this.project_id = '';
            this.products_nameList = [];
          }
        });
      }
    },
    // 上方的 导入数据（产品）
    //导入组件
    aa(file) {
      console.log(file);
    },
    handleRemoves(file, fileList) {
      console.log(file, fileList);
    },
    handlePreviews(file) {
      console.log(file);
    },
    handleExceeds(files, fileList) {
      message.warning(`当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemoves(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUpload(file) {
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ['xlsx']; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        // message({
        //   message: '请上传正确格式的项目文件',
        //   type: 'error',
        //   duration: 2000,
        // });
      }
      return suffixArray;
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.');
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return '';
      }
    },
    //note替换产品导入方式  中文导入
    async uploadFiles(params) {
      console.log('英文导入');
      const file = params.file;
      const form = new FormData();
      form.append('file', file);
      console.log(params);
      form.append('sub', this.$store.state.userid);
      await HttpClient.post('program/programData_upload2', form, {
        headers: { 'Conten-Type': 'multipart/form-data' },
      }).then((res) => {
        console.log(res, '接口res');
        if (res.project_name == null && res.status == 0) {
          console.log('我进来了', res.project_name == null, res.status == 0);
          this.numNoName = 1;
        }
        if (res.status == 1) {
          this.numNoName = 2;
        }
        // if(!res.fileName.includes(".xlsx")){
        //       this.numNoName = 1
        // }
        this.Result = res;
        this.resourceProduct = JSON.parse(JSON.stringify(res.result));
        this.resourceProject_name = JSON.parse(JSON.stringify(res.project_name));
        this.resourceCategory = JSON.parse(JSON.stringify(res.category));
        this.xlsx_name = res.fileName;
        this.import_val = res.result;
        //导入产品前面的 check 值
        for (let index = 0; index < this.import_val.length; index++) {
          this.CheckedArr.push(false);
        }
        //产品来源判断中英文显示
        if (this.$store.state.lang == 'en') {
          this.Result.result.forEach((item) => {
            if (item[4] == '市售产品') {
              item[4] = 'In-market Product';
            } else if (item[4] == '开发原型') {
              item[4] = 'Prototype';
            } else if (item[4] == '竞争品牌') {
              item[4] = 'Benchmark';
            }
            switch (item[5]) {
              case '膏霜':
                item[5] = 'Cream';
                break;
              case '乳液':
                item[5] = 'Lotion';
                break;
              case '啫喱':
                item[5] = 'Gel';
                break;
              case '油类':
                item[5] = 'Oil';
                break;
              case '其他':
                item[5] = 'Other';
                break;
              case '涂抹式面膜':
                item[5] = 'Smear mask';
                break;
              case '贴片式面膜':
                item[5] = 'Sheet mask';
                break;
              case '水类':
                item[5] = 'Toner';
                break;
            }
            switch (item[8]) {
              case '化学':
                item[8] = 'Chemical';
                break;
              case '物理':
                item[8] = 'Physical';
                break;
              case '物化结合':
                item[8] = 'Hybird';
                break;

              default:
                break;
            }
          });
        } else {
          this.Result.result.forEach((item) => {
            if (item[4] == 'In-market Product') {
              item[4] = '市售产品';
            } else if (item[4] == 'Prototype') {
              item[4] = '开发原型';
            } else if (item[4] == 'Benchmark') {
              item[4] = '竞争品牌';
            }
            switch (item[5]) {
              case 'Cream':
                item[5] = '膏霜';
                break;
              case 'Lotion':
                item[5] = '乳液';
                break;
              case 'Gel':
                item[5] = '啫喱';
                break;
              case 'Oil':
                item[5] = '油类';
                break;
              case 'Other':
                item[5] = '其他';
                break;
              case 'Smear mask':
                item[5] = '涂抹式面膜';
                break;
              case 'Sheet mask':
                item[5] = '贴片式面膜';
                break;
              case 'Toner':
                item[5] = '水类';
                break;
            }
            switch (item[8]) {
              case 'Chemical':
                item[8] = '化学';
                break;
              case 'Physical':
                item[8] = '物理';
                break;
              case 'Hybird':
                item[8] = '物化结合';
                break;

              default:
                break;
            }
          });
        }
        var status = res.status;
        this.down_name = res.downloadPath;
        //上传失败
        if (status == 1) {
          message.warning(res.message);
          return;
        } else {
          message({
            showClose: true,
            message: this.$t(`message.Message.Import`),
            type: 'success',
          });
          for (let index = 0; index < this.import_val[0].length; index++) {
            this.ProductNull.push('');
          }
          this.isDisable = false;
        }
        if (res.name != '') {
          this.upload_success = false;
          this.$store.commit('$_change_upload', false);

          this.pro_name = res.name;
        }
      });
    },

    //必须上传 文件提示
    err_message() {
      message({
        showClose: true,
        message: '请导入数据文件，否则进行分析',
        type: 'warning',
      });
    },
    //产品必须相同（不可删除）
    err_messages() {
      message({
        showClose: true,
        message: '请保证上传文件中的产品名称，和您所保存的相同',
        type: 'warning',
      });
    },

    //获取option的值
    async getOptions() {
      let response = await HttpClient.post('productinfo/getOptions');
      console.log('options-->', response);
      this.$set(this.cz_arr, 'color_Arr', response.color);
      this.$set(this.cz_arr, 'quality_Arr', response.quality);
      this.$set(this.cz_arr, 'classification_Arr', response.classification);
      this.$set(this.Products_model, 'color_Arr', response.color);
      this.$set(this.Products_model, 'quality_Arr', response.quality);
      this.$set(this.Products_model, 'classification_Arr', response.classification);
      this.$set(this.import_model, 'color_Arr', response.color);
      this.$set(this.import_model, 'quality_Arr', response.quality);
      this.$set(this.import_model, 'classification_Arr', response.classification);
    },
    //保存产品信息
    JudgeNum() {
      this.CheckedArr.forEach((item, index) => {
        this.$set(this.CheckedArr, index, false);
      });
      if (this.isDisable == true) {
        message({
          type: 'warning',
          message: '当前产品信息已保存!',
          showClose: true,
        });
      } else {
        this.repetitionIndex = []; //重置重复产品名字下标
        // --->校验数据维度0-15;
        let sum = 0;
        let judege = new RegExp(/^([0-9]|1[0-5])(\.[0-9])?$/);
        for (let i = 0; i < this.import_val.length; i++) {
          for (let j = 12; j < 53; j++) {
            if (!judege.test(this.import_val[i][j])) {
              sum += 1;

              console.log('this.import_val[i][j] :>> ', this.import_val[i][j] + '不符合规矩');
              console.log('this.import_val[i][j] :>> ', typeof this.import_val[i][j]);
              message({
                type: 'warning',
                message: this.$t(`message.Project.new.informationError`),
                showClose: true,
              });
            }
          }
        }

        //判断名字
        let arrName = [];
        //将所有名字导入进新数组
        this.import_val.forEach((item) => {
          arrName.push(item[0]);
        });
        // console.log('arrName :>> ', arrName);
        //判断重复
        arrName.map((val, index) => {
          if (arrName.indexOf(val) != arrName.lastIndexOf(val)) {
            console.log('重复项的下标:' + index);
            console.log('重复项的值:' + val);
            this.repetitionIndex.push(index); //记录第一个下标
            message({
              type: 'warning',
              message: '一个项目内产品名称不可重复，请进行修改!',
              showClose: true,
            });
            sum += 1;
          }
        });
        console.log(' this.repetitionIndex :>> ', this.repetitionIndex);
        //判断名字为空
        arrName.forEach((item) => {
          if (item == '') {
            sum += 1;
            this.Once_sure = true;
          }
        });

        // console.log('sum :>> ', sum);
        if (sum == 0) {
          this.isDisable = true;
          message({
            type: 'success',
            message: this.$t(`message.Project.new.SaveD`),
            showClose: true,
          });
        }
      }
      this.$store.commit('$_setChangeRoute', true);
    },
    //鼠标失焦,保留两位小数
    setTowNum(i, j) {
      console.log('this.import[i][j] :>> ', this.import_val[i][j]);
      if (j > 10) {
        //  this.import_val[i][j].toFixed(1)
        this.$set(this.import_val[i], j, Number(this.import_val[i][j]).toFixed(1));
        console.log('this.import[i][j] :>> ', Number(this.import_val[i][j]).toFixed(1));
      }
    },
    //修改产品数据
    amendMethod() {
      if (this.isDisable == false) {
        message({
          type: 'warning',
          message: '目前已在编辑状态!',
          showClose: true,
        });
      } else {
        this.isDisable = false;
        this.resourceProduct = JSON.parse(JSON.stringify(this.import_val));
        this.resourceProject_name = JSON.parse(JSON.stringify(this.Result.project_name));
        this.resourceCategory = JSON.parse(JSON.stringify(this.Result.category));
      }
    },
    //删除产品信息
    DeleteProduct() {
      if (this.CheckedArr.indexOf(true) == -1) {
        message({
          type: 'warning',
          message: this.$t(`message.Message.Atleast`),
          showClose: true,
        });
      } else {
        this.CheckedArr.forEach((item, index) => {
          this.Once_sureDelete = 1;
          if (item == true) {
            console.log('index :>> ', index);
            console.log('this.import_val[index][0] :>> ', this.import_val[index][0]);
          }
        });
      }
    },
    //复制产品
    CopyMethod() {
      if (this.isDisable == true) {
        message({
          type: 'warning',
          message: '请在编辑状态下复制产品!',
          showClose: true,
        });
      } else {
        let index = 0;
        this.CheckedArr.forEach((item) => {
          if (item == true) {
            index++;
          }
        });
        if (index == 1) {
          let i = this.CheckedArr.indexOf(true);

          console.log('i :>> ', i);
          let temporary = JSON.parse(JSON.stringify(this.import_val[i]));
          this.import_val.splice(i, 0, temporary);
        } else if (index == 0) {
          message({
            type: 'warning',
            message: this.$t(`message.Message.copySelect`),
            showClose: true,
          });
        } else {
          message({
            type: 'warning',
            message: this.$t(`message.Project.onlyOne`),
            showClose: true,
          });
        }
      }
    },
    //新建产品
    addProduct() {
      if (this.isDisable == true) {
        message({
          type: 'warning',
          message: this.$t(`message.Project.NotAdd`),
          showClose: true,
        });
      } else {
        this.import_val.push(JSON.parse(JSON.stringify(this.ProductNull)));
        this.$nextTick(() => {
          let scrollEl = this.$refs.scrollRef;
          scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
        });
      }
    },
    //修改项目名字的确认按钮
    amendProject() {
      if (this.amendProjectValue == '') {
        message({
          type: 'warning',
          message: this.$t(`message.Message.emptyNo`),
          showClose: true,
        });
      } else {
        this.Result.project_name = JSON.parse(JSON.stringify(this.amendProjectValue));
        this.isAmendNumber = false;
      }
    },
    //给修改项目名称赋值
    isShowChangeName() {
      this.isAmendNumber = true;
      console.log('赋值 :>> ', this.Result.project_name);
      this.amendProjectValue = JSON.parse(JSON.stringify(this.Result.project_name));
    },
    //产品取消操作
    CancleEdit() {
      this.Result.project_name = this.resourceProject_name;
      this.import_val = this.resourceProduct;
      this.Result.category = this.resourceCategory;
      this.isDisable = true;
    },
  },

  created() {
    // 接收mydraft传递过来的数据
    this.$EventBus.$off('sendDraft');
    this.$EventBus.$on('sendDraft', (data) => {
      this._program = data;
      console.log('接收到草稿箱 :>> ');
      this.FromDraft = true;
      console.log(this._program);
      this.import_val = this._program.products;

      this.Result = this._program.program;
      this.resourceProject_name = JSON.parse(JSON.stringify(this._program.program.project_name));
      this.resourceProduct = JSON.parse(JSON.stringify(this._program.products));
      this.resourceCategory = JSON.parse(JSON.stringify(this._program.program.category));
      //导入产品前面的 check 值
      for (let index = 0; index < this.import_val.length; index++) {
        this.CheckedArr.push(false);
      }
    });
    // console.log('this._program :>> ', this._program);
    // console.log('object :>> ', JSON.stringify(this._program) == '{}');
  },
  mounted() {
    this.$store.commit('$_setChangeRoute', false);
    console.log(this.Res_ID.program);
    this.req_category();
    this.getNowDate(); //调用时间函数
    // this.set_time();
    this.getOptions();
    // let b = JSON.stringify(this._program) == '{}';
    console.log('this._program :>> ', this._program);
    console.log('object :>> ', JSON.stringify(this._program) == '{}');
    // 判断进入路径,显示loding加载动画
    if (this.$store.state.upload == false) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    }
  },
  beforeDestroy() {
    this.$store.commit('$_setChangeRoute', true);
  },
};
</script>

<style>
div.tooltipStyless {
  opacity: 0.8;
  width: 130px;
}
</style>

<style lang="less" scoped>
@import url('../../style/Project/CreateProject.less');
</style>
